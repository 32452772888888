import React, { Component } from "react";
import Layout from "../components/layout";
import { queryGraphql } from "../services/api";
import { buildBlocks } from "../services/builder";
import { PreviewBlocks } from "../templates/fragment";

class Preview extends Component {
  state = {
    loading: true,
    title: "",
    blocks: [],
    meta: {},
  };

  constructor(props) {
    super(props);
    if (typeof window !== "undefined") {
      const parsedUrl = new URL(window.location.href);
      const id = parsedUrl.searchParams.get("id");
      const token = parsedUrl.searchParams.get("token");
      const endpoint = parsedUrl.searchParams.get("endpoint");

      const blocks = PreviewBlocks;

      const queryObject = {
        query: `
                {
                    page(id: "${id}", idType: DATABASE_ID) {
                        title
                        Meta {
                            fieldGroupName
                            metaDescription
                            metaTitle
                            ogDescription
                            ogTitle
                            twitterCardType
                            twitterDescription
                            twitterTitle
                            twitterImage {
                                uri
                            }
                            ogImage{
                                uri
                            }                            
                        }                       
                        blocks {
                            ... on CoreBlock {
                                reusableBlock {
                                  __typename
                                  ... on ReusableBlock {
                                    id
                                    blocks {
                                      ${blocks}
                                      innerBlocks {
                                          name
                                          ${blocks}
                                          innerBlocks {
                                              name
                                              ${blocks}
                                              innerBlocks {
                                                  name
                                                  ${blocks}                            
                                              }                                     
                                          }                          
                                      }                                         
                                    }
                                  }
                                }
                              } 
                            name
                            ${blocks}
                            innerBlocks {
                                name
                                ${blocks}
                                innerBlocks {
                                    name
                                    ${blocks}
                                    innerBlocks {
                                        name
                                        ${blocks}                            
                                    }                                     
                                }                          
                            }    
                        }
                    },
                }
                `,
      };
      this.createContent(queryObject, token, endpoint);
    }
  }

  createContent = (queryObject, token, endpoint) => {
    // console.log(queryObject)
    queryGraphql(queryObject, token, endpoint, (response) => {
      // console.log(response);
      const page = response.data.data.page;
      if (page === null) {
        this.setState({
          error: true,
          errorMessage:
            "Unable to fetch page, try refreshing the admin panel page before clicking preview.",
        });
      } else {
        const blocks = buildBlocks(page.blocks);
        this.setState({
          loading: false,
          blocks: blocks,
          title: page.title,
          meta: page.Meta,
          themeOptions: response.data.data.themeOptions,
        });
      }
    });
  };

  render() {
    if (this.state.loading === true) {
      return (
        <Layout meta={{}} title="Preview" path={null}>
          <h1>Preview</h1>
          <p>Loading...</p>
          {this.state.error === false ? (
            ""
          ) : (
            <p className={"error"}>{this.state.errorMessage}</p>
          )}
        </Layout>
      );
    } else {
      return (
        <Layout meta={this.state.meta} title={`Preview: ${this.state.title}`}>
          {this.state.blocks.map((block) => {
            return block;
          })}
        </Layout>
      );
    }
  }
}

export default Preview;
